.menu {

  &__tags {
    position: absolute;
    top: -12px;
    left: -8px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .menu__tag {
      display: inline-block;
      padding: 2px 4px;
      background: #7973CA;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      margin-right: 4px;

      &--green {
        background: #00C48C;
      }
    }
  }

  &__files {
    display: grid;
    gap: 10px;
    grid-template-columns: 50px 100px;


    &__page_image {
      position: relative;

      &__mini {
          width: 46px;
          max-height: 46px;
          object-fit: contain;
          padding: 6px;
          background-color: #f7f7f7;
          background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
          background-size: 4px 4px;
      }

      &__big {
        display: none;
      }

      &:hover {
        .menu__files__page_image__big {
          display: flex;
          position: absolute;
          bottom: 50%;
          left: 50%;
          border-radius: 24px 24px 24px 0;
          overflow: hidden;
          border: 1px solid #C3BFE9;
          background-color: #f7f7f7;
          background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
          background-size: 8px 8px;

          .box__inside {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .menu__files__download {
              display: flex;
              position: absolute;
              align-items: center;
              justify-content: center;
              border: none;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: #6750A4;
              cursor: pointer;
            }

            img {
              width: 160px;
              height: 160px;
              object-fit: contain;
            }
          }
        }
      }
    }

    &__pdf {
      display: flex;
      width: 150px;
      overflow: hidden;
      align-items: center;

      .name {
        font-size: 14px;
        color: #6C7487;
        text-decoration: none;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        margin: 0;
      }
    }
  }

  &__no_icon {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #6C7487;

    .menu__icon__img {
      max-height: 38px;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #6C7487;
    font-size: 14px;

    &__img {
      width: 52px;
      height: 52px;
      max-height: 52px;
      object-fit: contain;
      padding: 6px;
      background-color: #f7f7f7;
      background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
      background-size: 4px 4px;
    }
  }

  //&__original_file {
  //  font-size: 18px;
  //  color: #6C7487;
  //  text-align: center;
  //  justify-content: center;
  //  margin-top: 0 !important;
  //  margin-bottom: 10px !important;
  //
  //  .icon {
  //    width: 16px;
  //    margin-right: 4px;
  //  }
  //
  //  a.name {
  //    color: #6C7487;
  //    text-decoration: none;
  //    overflow: hidden;
  //    text-wrap: nowrap;
  //    text-overflow: ellipsis;
  //  }
  //}

  &__image {
    position: relative;
    padding-bottom: 90%;
    overflow: hidden;
    background-color: #f7f7f7;
    background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
    background-size: 10px 10px;
    border-radius: 6px;

    &.overlay {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 1;
      }
    }

    &-container {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: 100%;
      padding: 8px;
    }

    img {
      max-height: 70px;
      max-width: 50px;
      border-radius: 4px;
      object-fit: contain;
    }
  }

  &__name {
    font-family: Lato, Helvetica Neue, sans-serif !important;
    font-size: 26px;
    text-align: center;
    color: #6c7487;
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    box-shadow: 0 4px 20px rgb(0 0 0 / 2%);
    border: 1px solid #E1E4EA;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    width: 100%;
    height: 100%;

    &.shake {
      animation: shake 0.2s 2;
    }

    .btn.delete {
      position: absolute;
      right: -10px;
      top: -14px;
      background: transparent;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
    color: #6C7487;
    margin-right: 6px;

    &--bold {
      font-weight: 600;
    }
  }

  &__row {
    margin: 10px 0 0;
    display: flex;
    align-items: center;
    flex-direction: row;

    &--vertical {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    color: #6c7487;
    font-family: Lato,Helvetica Neue,sans-serif!important;
    align-self: flex-start;
  }

  &__value {
    font-size: 18px;
    line-height: 22px;
    color: #6c7487;
    font-family: Lato,Helvetica Neue,sans-serif!important;
    display: inline-flex;
    align-items: center;
    flex: 1;

    & > * {
      margin-right: 6px;
    }

    & > *:last-child {
      margin-right: 0;
    }

    &__restaurants {
      font-size: 14px;
    }

    &--pattern {
      background-color: #f7f7f7;
      background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
      background-size: 10px 10px;
      justify-content: center;
      width: 100%;
      padding: 4px;
      border-radius: 6px;
    }
  }

  &__value__img {
    width: 20px;
    margin-right: 6px;

    &--large {
      width: 52px;
    }
  }

  &__edit_name {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    input {
      font-size: 18px;
      color: #6c7487;
      font-family: Lato,Helvetica Neue,sans-serif!important;
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      border-bottom: 1px dashed #6c7487;
    }

    .mat-error {
      margin-top: 6px;
    }
  }

  &__edit_icon {
    margin-top: 6px;
  }

  &__edit_restaurants {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    z-index: 2;

    &__label {
      background: #F4F3FA;
      color: #6C7487;
      border: 1px dashed #7973CA;
      display: block;
      width: 100%;
      padding: 10px;
      text-align: center;
      border-radius: 6px;
      font-size: 14px;
      cursor: copy;
      transition: transform 0.2s;
      max-width: 200px;
    }

    &__title {
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 700px;

    &-loader {
      display: grid;
      grid-template-columns: repeat(6, 25% 15% 5% 20% 10% 20%);
      align-items: center;
      box-shadow: 0 4px 20px rgb(0 0 0 / 2%);
      border: 1px solid #E1E4EA;
      border-radius: 10px;
      padding: 20px;
      position: relative;
    }

    &-row {
      height: 50px;
      display: flex;
      align-items: center;
    }

    &-field {

      &.lang_icon {
        width: 20px;
        height: 20px;
        max-width: 20px;
        margin-right: 6px;
      }

      &.img {
        width: 50px;
        height: 50px;
        max-width: 50px;
      }

      &.info {
        width: 100%;
        max-width: 200px;
        margin: 0 10px -20px 10px;
      }
    }
  }

  &__not-found {
    text-align: center;
    font-size: 18px;
    color: #6C7487;
    font-weight: 600;
    overflow: hidden;
    margin: 30px 0 20px 20%;
    width: 40vw;
    font-family: Lato, "Helvetica Neue", sans-serif;
  }
}

@keyframes shake {
  0% {
    transform: rotate(-0.5deg);
  }

  50% {
    transform: rotate(0.5deg);
  }
}
