@import "~angular-notifier/styles";

.close-link {
  position: relative;
  width: 4rem;
  height: 4rem;

  .close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    overflow: hidden;
  }

  .close:hover {
    opacity: .5;
  }

  .close:before, .close:after {
    position: absolute;
    left: 18px;
    content: " ";
    height: 26px;
    width: 1px;
    top: 6px;
    background-color: #2C3E50;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }
}

.container {
  width: 100%;
  max-width: 1100px;
  margin:  2% auto 0 auto;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  margin-top: 2%;
}

.notification-badge {
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  margin-top: -20px;
  margin-left: 4px;
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 40px;

  .title {
    font-size: 40px;
    line-height: 48px;
    color: #6C7487;
    margin: 0;
    padding: 0;
  }

  .btn__icon {
    z-index: 2;
  }

  .actions {
    display: flex;
    gap: 4px;

    &.skeleton-loader {
      ::ng-deep {
        .loader {
          width: 98px;
          height: 44px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.mat-tooltip {
  word-break: break-word;
}

/* spinner */
::ng-deep {
  .mat-spinner {
    &.mat-secondary {
      circle {
        stroke: #f7f7f7;
      }
    }
    &.mat-primary {
      circle {
        stroke: #7973CA;
      }
    }
    &.mat-warn {
      circle {
        stroke: #e72e2e;
      }
    }
  }
}
