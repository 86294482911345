/* You can add global styles to this file, and also import other style files */

@import "styles/modal";
@import "styles/button";
@import "styles/base";
@import "styles/menu";

* {
  box-sizing: border-box;
  &:active,
  &:hover,
  &:focus {
    outline: 0;
  }
}

html {
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Lato, "Helvetica Neue", sans-serif !important; font-size: 16px; }

h1, h2, h3 { font-family: Lato, "Helvetica Neue", sans-serif !important; }

button {
  font-family: Lato, sans-serif;
}
