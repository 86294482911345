.modal {
  width: 60vw;
  max-width: 650px;
  position: relative;

  .close-link {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0;
    right: 0;

    .close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      overflow: hidden;
    }

    .close:hover {
      opacity: .5;
    }

    .close:before, .close:after {
      position: absolute;
      left: 18px;
      content: " ";
      height: 26px;
      width: 1px;
      top: 6px;
      background-color: #2C3E50;
    }

    .close:before {
      transform: rotate(45deg);
    }

    .close:after {
      transform: rotate(-45deg);
    }
  }

  &__title {
    text-align: center;
    font-size: 40px !important;
    line-height: 47px !important;
    color: #6C7487;
    padding: 70px 0 50px 0;
    margin-bottom: 0 !important;
  }

  &__title2 {
    text-align: center;
    font-size: 40px !important;
    line-height: 47px !important;
    color: #000000;
    padding: 70px 0 50px 0;
    margin-bottom: 0 !important;
  }

  &__subtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    width: 100%;
    margin: 0 auto;
  }

  &__form {
    width: 100%;
    max-width: 510px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &.info {
      max-width: 560px;
      margin: 40px auto 0;
    }

    .mat-form-field {
      margin-bottom: 10px;
    }

    .mat-form-field-infix {
      background: white;
    }

    .mat-input-element {
      background: white;
      font-size: 18px;
      line-height: 21px;
      color: #6C7487;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: #E3E3E3;
    }
  }

  &__submit-btn {
    margin: 50px 0 70px 0;
  }

  &__info-btn {
    margin-top: 30px;

    button {
      border: none;
      background: black;
      font-size: 24px;
      line-height: 28px;
      color: white;
      text-align: center;
      padding: 14px 75px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

//.cdk-overlay-pane {
//  overflow: auto;
//}

.mat-dialog-container {
  border-radius: 0 !important;
}

.confirmation-modal {
  .modal {
    max-width: 350px;

    .close-link {
      top: -4px;
      right: -10px;
    }
  }
}
