@mixin button-fill($color) {
  background-color: $color;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: darken($color, 4%);
    }

    &:active {
      background-color: lighten($color, 4%);
    }
  }
}


.btn {
  $bg_primary: #7973CA;
  $bg_secondary: #6C757D;
  $bg_active: #28A745;
  $bg_warning: #e72e2e;

  cursor: pointer;
  text-align: center;
  border: none;
  font-size: 16px;

  &:disabled {
    opacity: .65;
    cursor: not-allowed;
  }

  &__primary,
  &__secondary,
  &__active,
  &__warning {
    height: 45px;
    text-align: center;
    border-radius: 4px;
    padding: 12px 20px 14px;
    line-height: 1;
    color: #F7F7F7;

    &.hours {
      max-width: 150px;
    }
  }

  &__primary {
    @include button-fill($bg_primary);
  }

  &__secondary {
    @include button-fill($bg_secondary);
  }

  &__active {
    @include button-fill($bg_active);
  }

  &__warning {
    @include button-fill($bg_warning);
  }

  &__link {
    position: relative;
    background: transparent;
    color: #6C7487;
    padding: 0;
    border-bottom: 1px solid #6C7487;

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: darken(#6C7487, 12%);
      }

      &:active {
        color: lighten(#6C7487, 12%);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__block {
    width: 100%;
  }

  &.btn-delete__small {
    background: none;
  }
}

.add-language-btn {
  font-weight: 500;
  background: none;
  color: #7973CA;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:not(:disabled) {
    &:hover {
      text-shadow: 0 0 1px #7973CA;
    }
  }
}
