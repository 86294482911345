.menu {

  &__tags {
    position: absolute;
    top: -12px;
    left: -8px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .menu__tag {
      display: inline-block;
      padding: 2px 4px;
      background: #7973CA;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      margin-right: 4px;

      &--green {
        background: #00C48C;
      }
    }
  }

  &__original_file {
    font-size: 18px;
    color: #6C7487;
    text-align: center;
    justify-content: center;
    margin-top: 0 !important;
    margin-bottom: 10px !important;

    .icon {
      width: 16px;
      margin-right: 4px;
    }

    a.name {
      color: #6C7487;
      text-decoration: none;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__image {
    position: relative;
    padding-bottom: 90%;
    overflow: hidden;
    background-color: #f7f7f7;
    background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
    background-size: 10px 10px;
    border-radius: 6px;

    &.overlay {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 1;
      }
    }

    &-container {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: 100%;
      padding: 8px;
    }

    img {
      height: 100%;
      object-fit: contain;
      z-index: -1;
    }
  }

  &__name {
    font-family: Lato, Helvetica Neue, sans-serif !important;
    font-size: 26px;
    text-align: center;
    color: #6c7487;
  }

  &__item {
    box-shadow: 0 4px 20px rgb(0 0 0 / 2%);
    border: 1px solid #E1E4EA;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    width: 100%;
    height: 100%;

    &.shake {
      animation: shake 0.2s 2;
    }

    .btn.delete {
      position: absolute;
      right: -10px;
      top: -14px;
      background: transparent;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
    color: #6C7487;
    margin-right: 6px;

    &--bold {
      font-weight: 600;
    }
  }

  &__row {
    margin: 10px 0 0;
    display: flex;
    align-items: center;
    flex-direction: row;

    &--vertical {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    color: #6c7487;
    font-family: Lato,Helvetica Neue,sans-serif!important;
    align-self: flex-start;
  }

  &__value {
    font-size: 18px;
    line-height: 22px;
    color: #6c7487;
    font-family: Lato,Helvetica Neue,sans-serif!important;
    display: inline-flex;
    align-items: center;
    flex: 1;

    & > * {
      margin-right: 6px;
    }

    & > *:last-child {
      margin-right: 0;
    }

    &--pattern {
      background-color: #f7f7f7;
      background-image: radial-gradient(#7973CA 0.5px, #f7f7f7 0.5px);
      background-size: 10px 10px;
      justify-content: center;
      width: 100%;
      padding: 4px;
      border-radius: 6px;
    }
  }

  &__value__img {
    width: 20px;
    margin-right: 6px;

    &--large {
      width: 52px;
    }
  }

  &__edit_name {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    input {
      font-size: 18px;
      color: #6c7487;
      font-family: Lato,Helvetica Neue,sans-serif!important;
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      border-bottom: 1px dashed #6c7487;
    }

    .mat-error {
      margin-top: 6px;
    }
  }

  &__edit_icon {
    margin-top: 6px;
  }

  &__edit_restaurants {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    z-index: 2;

    &__label {
      background: #F4F3FA;
      color: #6C7487;
      border: 1px dashed #7973CA;
      display: block;
      width: 100%;
      padding: 10px;
      text-align: center;
      border-radius: 6px;
      font-size: 14px;
      cursor: copy;
      transition: transform 0.2s;
      max-width: 200px;
    }

    &__title {
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__skeleton {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    &-loader {
      box-shadow: 0 4px 20px rgb(0 0 0 / 2%);
      border: 1px solid #E1E4EA;
      border-radius: 10px;
      padding: 20px;
      position: relative;
    }

    &-row {
      &.img {
        position: relative;
        padding-bottom: 90%;
        margin-bottom: 10px;
      }
    }

    &-field {
      &.img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
        max-width: 250px;
      }

      &.info {
        max-width: 200px;
      }
    }
  }

  &__not-found {
    text-align: center;
    font-size: 18px;
    color: #6C7487;
    font-weight: 600;
    overflow: hidden;
    margin: 30px 0 20px 20%;
    width: 40vw;
    font-family: Lato, "Helvetica Neue", sans-serif;
  }
}

@keyframes shake {
  0% {
    transform: rotate(-0.5deg);
  }

  50% {
    transform: rotate(0.5deg);
  }
}
